@import './../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
  display: flex;
  gap: 1.5rem;
  flex-direction: column-reverse;
  overflow: hidden;

  @include media('>=1000px') {
    align-items: flex-start;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding-block: 5rem;
  }
}

.container {
  display: flex;

  @include media('>=1000px') {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    align-self: center;
  }
}

.img-container {
  @include media('>=1000px') {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
}

.media {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-container {
  overflow: hidden;
  position: relative;
  min-height: 220px;
  aspect-ratio: 952 / 536;

  &--intro {
    @include media('>=1000px') {
      width: 50%;
      margin-left: auto;
      height: 100%;
    }
  }

  &--video {
    &::after {
      @include transition(opacity);

      pointer-events: none;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgb(0 0 0 / 50%) 0%, rgb(0 0 0 / 50%) 100%);
    }

    &-played {
      &::after {
        opacity: 0;
      }
    }
  }
}

.cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // z index 1 so it's higher than the background gradient
  z-index: 1;

  --PillCTA__border: solid 4px rgb(255 255 255 / 60%);

  @include transition(opacity, background-color);

  &--played {
    opacity: 0;
    pointer-events: none;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);

  @include media('>=1000px') {
    width: 50%;
    margin-right: auto;

    // 10 rem in design but this scales slightly nicer
    padding-inline-end: 8vw;

    // padding-block-end: 5rem;
  }
}

.header {
  @include fonts__h4;
}

.desc {
  @include fonts__paragraph;
}
