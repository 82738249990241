@import './../../../styles/sass-resources/sass-resources';

.main {
  display: block;
  height: var(--NavigationHeader__height);
  position: sticky;
  top: 0;
  z-index: 4;
  pointer-events: auto;
  background-color: var(--colors__black);
  overflow: hidden;
  width: calc(100% + var(--scrollbar-width, 0));

  --NavigationHeader__nav-item-opacity: 0;
  --NavigationHeader__nav-item-x: -100%;

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    width: 100vw;
    background-color: var(--colors__black);
    z-index: -1;
  }
}

.wrapper {
  padding-block: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-container {
  position: relative;
  height: 64px;
  width: 176px;

  img {
    object-fit: contain;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.links {
  display: flex;
  height: min-content;
  gap: 2rem;

  @include media('<tablet-landscape') {
    display: none;
  }
}

.nav-text {
  position: relative;

  &::before {
    content: '/';
    position: absolute;

    @include transition(transform, opacity);

    font-weight: 700;
    left: -0.75rem;
    opacity: var(--NavigationHeader__nav-item-opacity);
    transform: translateX(var(--NavigationHeader__nav-item-x));
  }
}

.cta {
  @include fonts__primary-cta;

  color: var(--colors__white);

  &:hover {
    --NavigationHeader__nav-item-opacity: 0.6;
    --NavigationHeader__nav-item-x: 0;
  }

  &--active {
    --NavigationHeader__nav-item-opacity: 1;
    --NavigationHeader__nav-item-x: 0;
  }
}

.account {
  @include media('<tablet-landscape') {
    display: none;
  }
}

.skip-link {
  position: absolute;
  top: calc(var(--NavigationHeader__height) / 2);
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  line-height: 1;
  opacity: 0;

  @include fonts__primary-cta;

  color: var(--colors__white);

  @include transition(opacity);

  &:focus-visible {
    opacity: 1;
    z-index: 5;
  }
}
