@import './../../../styles/sass-resources/sass-resources';

.wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100dvh - var(--NavigationHeader__height));

  // color combos

  &--blue {
    background-color: var(--colors__azure);
  }

  &--green {
    background-color: var(--colors__verde);
  }

  &--purple {
    background-color: var(--colors__plum);
  }

  &--gradient {
    background: var(--NPTC-Gradient);
  }

  &--media {
    &::after {
      content: '';
      position: absolute;
      z-index: -2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgb(26 27 46 / 50%) 0%, rgb(26 27 46 / 50%) 100%);
    }
  }
}

.media {
  z-index: -3;

  &--video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// container of left and right

.content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    flex-direction: row;
    gap: 3rem;
  }
}

// left side styling

.left {
  padding-block: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 640px;
  width: 100%;

  @include media('>=tablet-landscape') {
    height: 100%;
    max-width: 50%;
    gap: 2.25rem;
  }
}

.headline {
  @include fonts__h1;

  @include media('>=tablet-landscape') {
    font-size: 5.5vw;
  }

  color: var(--colors__white);
}

.subHeadline {
  @include media('<tablet-landscape') {
    margin-block-start: 1rem;
  }

  @include fonts__body-L--Soleil;

  color: var(--colors__white);
}

.cta {
  @include media('<tablet-landscape') {
    margin-block-start: 2rem;
  }
}

// right side styling

.animate-container {
  width: 100%;

  @include media('>=tablet-landscape') {
    max-width: 50%;
  }
}

.featured-overview {
  background-color: var(--colors__white);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding-block: 2rem;
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    left: calc(-1 * var(--container__padding-x));
    top: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: var(--colors__white);
  }

  @include media('>=tablet-landscape') {
    &::after {
      display: none;
    }

    padding: 4rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__label {
    @include fonts__body-L--Soleil;
  }

  &__cta {
    // Removing padding so as to fit into container correctly per design.
    // The padding on the featured overview section should be enough to compensate for what I'm taking out here
    padding: 0;
    margin-inline-start: auto;
    text-align: right;
    padding-block-end: 6px;
  }

  // Each Card
  &__card {
    border-top: solid 1px var(--colors__dove);
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    &-heading-container {
      display: flex;
      flex-direction: column;
    }

    &-title {
      @include fonts__h5;
    }

    &-subHeadline {
      @include fonts__body-L--Soleil;

      color: var(--colors__grey);
    }

    &-desc {
      @include fonts__eyebrow;
    }

    &-cta {
      outline-color: var(--colors__black) !important;
    }
  }
}
