@mixin focus() {
  outline-offset: var(--focus-offset, 2px);

  &:focus-visible {
    outline: 2px dotted currentcolor;
  }
}

@mixin focus-inset() {
  @include focus;

  --focus-offset: -1px;
}

@mixin focus-within {
  &:has(:focus-visible) {
    outline-offset: 0.15rem;
    outline: 2px dotted currentcolor;
  }
}
