@import './../../../../styles/sass-resources/sass-resources';

.main {
  position: relative;

  // to sit on top of quote container
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1px var(--colors__black);

  @include transition(background-color);

  &--active {
    background-color: var(--colors__black);
  }
}

.navigation {
  display: flex;
  gap: 0.5rem;
  color: var(--colors__black);
}

.icon {
  background-color: var(--colors__black);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include transition(background-color, opacity);

  &:hover {
    background-color: var(--colors__grey);
  }
}

.chevron {
  @include transition(color);

  color: var(--colors__white);
  width: 0.75rem;
  height: 0.75rem;

  .next & {
    transform: rotate(-180deg);
  }
}
