@import './../../../styles/sass-resources/sass-resources';

// All commented out code will want to be filled out once font mixins have been created.

/* 
  reason for this weird selector: in order to have our styles injected into richtext editor in wp,
  along with also being used as styling for this rich text component on the front end. We need to select
  the global tinymce id (as modular stylesheets have a hash at the end), and nextjs requires a class which is why
  we have the ':not(.main)' following it, that selector doesn't do anything other than get us past checks. 
*/

/* stylelint-disable no-descending-specificity */
:global(#tinymce):not(.main),
.main {
  --RichText__spacing-y: var(--Richtext__spacing-y-override, var(--spacing-compact));

  // this is the same as what's on the main NPTC site
  --RichText__heading-spacing-y: 2rem;

  @include media('>=tablet-landscape') {
    --RichText__spacing-y: var(--Richtext__spacing-y-override, var(--spacing));
  }

  h1,
  :global(.h1-style) {
    @include fonts__h1;
  }

  // shrinking sizes of h2-h6 fonts as per client req

  h2,
  :global(.h2-style) {
    @include fonts__h3;
  }

  h3,
  :global(.h3-style) {
    @include fonts__h4;
  }

  h4,
  :global(.h4-style) {
    @include fonts__h5;
  }

  h5,
  :global(.h5-style) {
    @include fonts__h6;
  }

  h6,
  :global(.h6-style) {
    @include fonts__h6;
  }

  p,
  a,
  button {
    margin-top: var(--RichText__spacing-y);
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--RichText__heading-spacing-y);
  }

  h1,
  h2 {
    margin-top: calc(var(--RichText__heading-spacing-y) * 2);
  }

  ul,
  ol {
    margin-left: var(--RichText__list-margin-left, 2rem);
    margin-top: var(--RichText__spacing-y);
    padding: 0;
  }

  ul li {
    list-style-type: var(--RichText__list-style-type, disc);
  }

  ol li {
    list-style-type: var(--RichText__list-style-type, decimal);
  }

  li:not(:first-child) {
    margin-top: var(--RichText__spacing-y);
  }

  img,
  iframe,
  table,
  figure {
    max-width: 100%;
    margin-top: var(--spacing);
  }

  blockquote {
    @include fonts__h5;

    // removing top spacing for p elements
    --RichText__spacing-y: 0;

    text-align: center;
    margin: var(--spacing-spacious) 0;
    padding: var(--spacing-spacious);
    position: relative;

    &::before,
    &::after {
      display: block;
      content: ' ';
      height: 1px;
      background: var(--colors__black);
      width: 100%;
      left: 0;
      position: absolute;
    }

    &::before {
      margin-bottom: var(--spacing-spacious);
      top: 0;
    }

    &::after {
      margin-top: var(--spacing-spacious);
      bottom: 0;
    }
  }

  cite {
    display: block;
    margin-top: var(--RichText__spacing-y);
    padding-top: 0.5rem;
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  :global(.header) {
    @include fonts__h4;
  }

  :global(.sub-heading) {
    @include fonts__body-L--Soleil;

    color: var(--colors__grey);
  }

  :global(.primary-cta) {
    @include primary-cta;
  }

  :global(.p-large) {
    font-size: 1.25rem;
  }

  :global(.alignleft) {
    // TODO: play around with float clear
    float: left;
    margin-inline-end: var(--spacing);
    margin-block-end: var(--spacing);
  }

  :global(.alignright) {
    float: right;
    margin-inline-start: var(--spacing);
    margin-block-end: var(--spacing);
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
