@import './../../../styles/sass-resources/sass-resources';

.main {
  background-color: var(--colors__black);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.mobile-top__container {
  display: contents;

  @include media('<tablet-landscape') {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.mobile-bottom__container {
  display: contents;

  @include media('<tablet-landscape') {
    display: flex;
    gap: 1rem;
  }
}

.section {
  @include media('>=tablet-landscape') {
    flex: 1;
    padding-inline: 1rem;
  }
}

.top {
  display: flex;

  @include media('<tablet-landscape') {
    flex-direction: column;
    gap: 2rem;
  }
}

.bottom {
  display: flex;
  justify-content: center;

  @include fonts__paragraph--small;

  color: var(--colors__white);

  @include media('<tablet-landscape') {
    flex-direction: column;
    align-self: center;
  }
}

.bottom__ancillary {
  display: flex;
  flex-wrap: wrap;
}

.divider {
  color: var(--colors__white);
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding-inline: 0.15rem;
}

.socials {
  display: flex;
  gap: var(--spacing-compact);
  color: var(--colors__white);
}

.slash {
  color: var(--colors__grey);
}

.primaryNav {
  display: flex;
  flex-wrap: wrap;

  @include media('>=tablet-landscape') {
    flex-direction: column;
  }

  gap: var(--spacing-compact);

  &__item {
    color: var(--colors__white);

    &-text {
      @include fonts__body-L--Tiempos;
    }
  }
}

.contact {
  flex: 1 1 0px;
  color: var(--colors__white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-compact);

  &__label {
    @include fonts__footer-head;
  }

  &__company-container {
    display: flex;
    flex-direction: column;
  }

  &__company,
  &__address {
    @include fonts__footer-p;
  }
}

.signup {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);
  color: var(--colors__white);

  &__label {
    @include fonts__footer-head;
  }
}

.notices {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);
  color: var(--colors__white);

  &__label {
    @include fonts__footer-head;
  }
}

.ancil-links {
  white-space: nowrap;
}
