@mixin slideIn {
  &:global {
    animation: slide-in var(--transition-duration) var(--transition-easing)
      var(--transition-delay, 0ms) forwards;
  }
}

@mixin slideOut {
  &:global {
    animation: slide-out var(--transition-duration) var(--transition-easing)
      var(--transition-delay, 0ms) forwards;
  }
}

@mixin slideInDown {
  &:global {
    animation: slide-in-down var(--transition-duration) var(--transition-easing)
      var(--transition-delay, 0ms) forwards;
  }
}

@mixin slideOutDown {
  &:global {
    animation: slide-out-down var(--transition-duration) var(--transition-easing)
      var(--transition-delay, 0ms) forwards;
  }
}

@mixin doNothing {
  &:global {
    animation: do-nothing var(--transition-duration) var(--transition-easing)
      var(--transition-delay, 0ms) forwards;
  }
}
