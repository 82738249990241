@import './../../../styles/sass-resources/sass-resources';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding-block: 2rem;
  min-height: 696px;

  @include media('>=tablet-landscape') {
    padding-block: 15rem;
  }

  &--setMinHeight {
    padding-block: 0;
    min-height: calc(100dvh - var(--NavigationHeader__height));
  }

  // color combos

  &--blue {
    background-color: var(--colors__azure);
  }

  &--green {
    background-color: var(--colors__verde);
  }

  &--purple {
    background-color: var(--colors__plum);
  }

  &--gradient {
    background: var(--NPTC-Gradient);
  }

  &--media {
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgb(26 27 46 / 50%) 0%, rgb(26 27 46 / 50%) 100%);
    }
  }
}

.media {
  z-index: -2;

  &--video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media('>=tablet-landscape') {
    gap: 2.25rem;
    max-width: 800px;
  }
}

.headline {
  @include fonts__h1;

  color: var(--colors__white);
}

.subHeadline {
  @include media('<tablet-landscape') {
    margin-block-start: 1rem;
  }

  @include fonts__body-L--Soleil;

  color: var(--colors__white);
}

.cta {
  @include media('<tablet-landscape') {
    margin-block-start: 2rem;
  }
}
