@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  align-items: flex-start;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media('>=tablet-portrait') {
    justify-content: flex-start;
  }

  flex-wrap: wrap;

  --CardsContainer__gap: var(--spacing-compact);

  gap: var(--CardsContainer__gap);
}

.item {
  --CardsContainer__items-per-row: 1;
  --CardsContainer__gap: var(--spacing-compact); // 1rem

  gap: var(--CardsContainer__gap);

  @include media('>=tablet-portrait') {
    --CardsContainer__items-per-row: 2;
  }

  @include media('>=tablet-landscape') {
    --CardsContainer__items-per-row: 3;
  }

  @include media('>=1200px') {
    --CardsContainer__items-per-row: 4;
  }

  /* stylelint-disable scss/operator-no-newline-after */

  width: calc(
    100% / var(--CardsContainer__items-per-row) - var(--CardsContainer__gap) *
      (var(--CardsContainer__items-per-row) - 1) / var(--CardsContainer__items-per-row)
  );
  /* stylelint-enable scss/operator-no-newline-after */
}

.cta {
  align-self: center;
}

.headline {
  @include fonts__h5;
}
