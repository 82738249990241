@mixin primary-cta {
  @include fonts__primary-cta;
  @include transition(background-color);

  // defaulting to light theme

  --PillCTA__background-color: var(--colors__black);
  --PillCTA__text-color: var(--colors__white);

  // removing underline from links
  text-decoration: none;

  // defaulting to dark theme
  &.dark {
    --PillCTA__theme-color: var(--colors__black);
  }

  display: inline-flex;
  align-items: center;
  gap: var(--spacing-tight);
  border-radius: 600px;
  padding-inline: var(--cta-spacing-horizontal, rem(30px));
  padding-block: var(--cta-spacing-vertical, rem(20px));
  background-color: var(--PillCTA__background-color);
  color: var(--PillCTA__text-color);

  &:hover:not([disabled]) {
    --PillCTA__background-color: var(--colors__grey);
    --PillCTA__theme-color: var(--colors__grey);
  }
}
