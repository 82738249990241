@import './../../../styles/sass-resources/sass-resources';

.main {
  @include fonts__primary-cta;

  // defaulting to light theme

  --PillCTA__background-color: var(--colors__black);
  --PillCTA__text-color: var(--colors__white);

  // theme
  --PillCTA__theme-color: var(--colors__white);

  // border:
  --PillCTA__border-color: var(--PillCTA__theme-color);
  --PillCTA__border: solid 1px var(--PillCTA__border-color);

  &.dark {
    --PillCTA__theme-color: var(--colors__black);
  }

  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-tight);
  border-radius: 600px;
  padding-inline: var(--cta-spacing-horizontal, rem(30px));
  padding-block: var(--cta-spacing-vertical, rem(20px));
  background-color: var(--PillCTA__background-color);
  color: var(--PillCTA__text-color);

  @include transition(background-color, border-color);

  &:not(.transparent) {
    border: var(--PillCTA__border);
  }

  // there is no light theme for the primary pill variant, only dark theme. So this is to ovveride it to dark mode

  &.pill.light {
    --PillCTA__background-color: var(--colors__black);
    --PillCTA__theme-color: var(--colors__black);
    --PillCTA__text-color: var(--colors__white);
  }

  // pill is only varaint with hover state

  &.pill {
    &:hover:not([disabled]) {
      --PillCTA__background-color: var(--colors__grey);
      --PillCTA__theme-color: var(--colors__grey);
    }
  }

  // small size

  &.small {
    --cta-spacing-horizontal: 1.25rem;
    --cta-spacing-vertical: 0.625rem;

    @include fonts__primary-cta-S;
  }

  &.light {
    --PillCTA__text-color: var(--PillCTA__theme-color);
  }

  &.transparent,
  &.light,
  &.transparent-pill {
    --PillCTA__background-color: transparent;
  }

  &.transparent.dark {
    color: var(--PillCTA__theme-color);
  }

  &.transparent-pill.dark {
    --PillCTA__text-color: var(--PillCTA__theme-color);

    &:not(:hover) {
      --PillCTA__border-color: var(--colors__grey);
    }
  }

  // for disabled state without actually being disabled
  &--greyed {
    --PillCTA__background-color: transparent !important;
    --PillCTA__text-color: var(--colors__grey) !important;
    --PillCTA__border-color: var(--colors__grey) !important;
  }

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover:not([disabled]) {
    --transform-icon: 5px;
  }
}

.icon-right {
  @include transition(transform);

  transform: translateX(var(--transform-icon));
  width: 1.125rem;
  height: 1rem;

  &--small {
    height: 0.75rem;
    width: 0.75rem;
  }
}

.icon-left {
  height: 1rem;
  width: 1rem;

  &--small {
    height: 0.75rem;
    width: 0.75rem;
  }
}
