@import './../../../../../styles/sass-resources/sass-resources';

.main {
  width: 100%;
  grid-column: span 1;
  position: relative;
}

.container {
  padding: var(--spacing-compact);
  border: 1px solid var(--colors__grey);
  border-radius: 4px;
  position: relative;
  display: flex;
  gap: var(--spacing-compact);
  justify-content: space-between;
  align-items: center;

  @include focus-within;
}

.label {
  padding: 0.5rem 1rem;
  z-index: 2;

  @include fonts__caption;
}

.selected {
  @include fonts__body--Soleil;

  color: var(--colors__grey);
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  // To address issue in Safari where the height could not be changed unless this
  // property is used. https://stackoverflow.com/questions/13252835/safari-change-select-height
  appearance: none;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--colors__black);
  transform: rotate(-90deg);
}
