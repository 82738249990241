@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  height: 100%;
}

.container {
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding-inline: 1rem;
  gap: 2rem;
}

.links {
  display: flex;
  flex-direction: column;
  height: min-content;
  gap: 2rem;
}

.cta {
  @include fonts__body-L--Tiempos;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--colors__grey);
  flex-shrink: 0;
}

.label {
  @include fonts__body--Soleil--bold;
}

.account {
  text-transform: none;

  --cta-spacing-horizontal: 0px !important;
}

.lower {
  padding-block-start: var(--spacing);
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-direction: column;
  gap: var(--spacing-compact);
}

.socials {
  margin-block-start: var(--spacing-compact);
  display: flex;
  gap: var(--spacing-compact);
}
