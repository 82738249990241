/*
 *  Variables
 */

$fonts__font-family-tiempos: 'tiempos';
$fonts__font-family-soleil: 'soleil';
$fonts__font-family__sans-serif: $fonts__font-family-soleil, arial, sans-serif;
$fonts__font-family__serif: $fonts__font-family-tiempos, georgia, serif;
$fonts__tablet-breakpoint: '>=tablet-landscape';
$fonts__desktop-breakpoint: '>=desktop';
$fonts__font-weight--light: 300;
$fonts__font-weight--regular: 400;
$fonts__font-weight--medium: 500;
$fonts__font-weight--semi-bold: 600;
$fonts__font-weight--bold: 700;
$fonts__font-weight--heavy: 800;

/*
  *  Mixins
  */

@mixin font-face($font-name, $file-path-location, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;

    // woff2 formatting appended here instead of in the other fonts tiempos declaration
    src: url(#{$file-path-location}) format('woff2');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

/*
  *  Base font mixins
  */

@mixin fonts__sans-serif-medium() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--medium;
}

@mixin fonts__sans-serif-medium-italic() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--medium;
  font-style: italic;
}

@mixin fonts__sans-serif-regular() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--regular;
}

@mixin fonts__serif-semi-bold() {
  // only tiempos used
  font-family: $fonts__font-family__serif;
  font-weight: $fonts__font-weight--semi-bold;
}

@mixin fonts__sans-serif-semi-bold() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--semi-bold;
}

@mixin fonts__sans-serif-bold() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--bold;
}

@mixin fonts__sans-serif-heavy() {
  font-family: $fonts__font-family__sans-serif;
  font-weight: $fonts__font-weight--heavy;
}

@mixin fonts__sans-serif-heavy-italic() {
  font-family: $fonts__font-family__serif;
  font-weight: $fonts__font-weight--heavy;
  font-style: italic;
}

// Mixins Headings

@mixin fonts__display-L() {
  @include fonts__serif-semi-bold;

  line-height: 1.08;
  font-size: 96px;
}

@mixin fonts__display() {
  @include fonts__serif-semi-bold;

  line-height: 1.22;
  font-size: 72px;
}

@mixin fonts__heading-XL() {
  @include fonts__serif-semi-bold;

  line-height: 1.25;
  font-size: 64px;
}

@mixin fonts__heading-L() {
  @include fonts__serif-semi-bold;

  line-height: 1.28;
  font-size: 56px;
}

@mixin fonts__heading-M() {
  @include fonts__serif-semi-bold;

  line-height: 1.25;
  font-size: 48px;
}

@mixin fonts__heading-S() {
  @include fonts__serif-semi-bold;

  line-height: 1.4;
  font-size: 40px;
}

@mixin fonts__heading-XS() {
  @include fonts__serif-semi-bold;

  font-size: 32px;
  font-style: normal;
  line-height: 1.25;
}

// Mixins Body

@mixin fonts__body-L--Tiempos() {
  @include fonts__serif-semi-bold;

  line-height: 1.5;
  font-size: 24px;
}

@mixin fonts__body-L--Soleil() {
  @include fonts__sans-serif-semi-bold;

  line-height: 1.5;
  font-size: 24px;
}

@mixin fonts__body--Tiempos() {
  @include fonts__serif-semi-bold;

  line-height: 1.5;
  font-size: 16px;
}

@mixin fonts__body--Soleil() {
  @include fonts__sans-serif-regular;

  line-height: 1.5;
  font-size: 16px;
}

@mixin fonts__body--Soleil--bold() {
  @include fonts__sans-serif-bold;

  line-height: 1.5;
  font-size: 16px;
}

@mixin fonts__body-S() {
  @include fonts__sans-serif-regular;

  line-height: 1.3;
  font-size: 12px;
}

@mixin fonts__body-S--bold() {
  @include fonts__sans-serif-bold;

  line-height: 1.3;
  font-size: 12px;
}

@mixin fonts__disclaimer() {
  @include fonts__sans-serif-regular;

  line-height: 1.3;
  font-size: 12px;
}

@mixin fonts__caption() {
  @include fonts__sans-serif-regular;

  line-height: 1.45;
  font-size: 11px;
}

@mixin fonts__subhead--bold() {
  @include fonts__sans-serif-bold;

  line-height: 1.5;
  font-size: 16px;
}

// Mixins CTA

@mixin fonts__all-caps-L() {
  @include fonts__sans-serif-heavy;

  line-height: 1.15;
  letter-spacing: 1.68px;
  font-size: 14px;
  text-transform: uppercase;
}

@mixin fonts__all-caps-S() {
  @include fonts__sans-serif-heavy;

  line-height: 1.3;
  letter-spacing: 1.44px;
  font-size: 12px;
  text-transform: uppercase;
}

@mixin fonts__all-caps-XS() {
  @include fonts__sans-serif-heavy;

  line-height: 1.5;
  letter-spacing: 0.96px;
  font-size: 8px;
  text-transform: uppercase;
}

// ONI Styles

@mixin fonts__h1() {
  @include fonts__heading-S;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__display-L;
  }
}

@mixin fonts__h2() {
  @include fonts__heading-S;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-XL;
  }
}

@mixin fonts__h3() {
  @include fonts__heading-S;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-M;
  }
}

@mixin fonts__h4() {
  @include fonts__heading-XS;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-S;
  }
}

@mixin fonts__h5() {
  @include fonts__heading-XS;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-XS;
  }
}

@mixin fonts__h6() {
  @include fonts__body--Tiempos;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-XS;
  }
}

@mixin fonts__h7() {
  @include fonts__body-L--Tiempos;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-XS;
  }
}

@mixin fonts__paragraph() {
  @include fonts__body--Soleil;
}

@mixin fonts__paragraph--small() {
  @include fonts__body-S;
}

@mixin fonts__eyebrow() {
  @include fonts__body--Soleil;
}

@mixin fonts__primary-cta {
  @include fonts__all-caps-L;
}

@mixin fonts__primary-cta-S {
  @include fonts__body--Soleil;
}

@mixin fonts__footer-head() {
  @include fonts__body-S--bold;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__body--Soleil--bold;
  }
}

@mixin fonts__footer-p() {
  @include fonts__body-S;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__body--Soleil;
  }
}

@mixin fonts__subhead {
  @include fonts__subhead--bold;
}

@mixin fonts__quote {
  @include fonts__body-L--Tiempos;

  @include media($fonts__tablet-breakpoint) {
    @include fonts__heading-S;
  }
}
