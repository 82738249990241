@import './../../../styles/sass-resources/sass-resources';

.main {
  // margin-top: 100vh;
  // offset for padding on desktop of the image in relation to its' background
  --QuoteSlider--offset: 2.5rem;
  --QuoteSlider--mobile-img-height: 320px;
  --QuoteSlider__theme: var(--colors__azure);
  --QuoteSlider__aspect-ratio: 675 / 580;

  &.green {
    --QuoteSlider__theme: var(--colors__verde);
  }
}

.container {
  display: flex;
  flex-direction: column;

  @include media('<tablet-landscape') {
    overflow: hidden;
  }

  @include media('>=tablet-landscape') {
    flex-direction: row;
    gap: 5rem;
  }
}

.slider-nav-wrapper {
  opacity: 0;

  @include transition(opacity, transform);

  transform: translateY(50px);
  display: block;

  &--hasTriggered {
    opacity: 1;
    transform: translateY(0);
  }
}

.img {
  visibility: hidden;

  &--active {
    visibility: visible;
  }
}

.media-container {
  position: relative;

  // needs to be explicitly set like this otherwise height of cards will change on slide change,
  // see storybook story for 'one-image'

  &--noImg {
    @include media('<tablet-landscape') {
      height: 0;
    }

    @include media('>=tablet-landscape') {
      width: 40%;
      display: grid;
    }
  }

  &--img {
    @include media('<tablet-landscape') {
      left: calc(-1 * var(--spacing-compact));
      width: 100vw;
      aspect-ratio: var(--QuoteSlider__aspect-ratio);
      margin-block: 1rem;
    }

    @include media('>=tablet-landscape') {
      margin-block: var(--QuoteSlider--offset);
      width: 40%;
      display: grid;
    }
  }
}

.media-outer {
  position: absolute;

  --QuoteSlider__gutter: var(--container__padding-x);

  // grabbing the gutter of the one side of the screen when on desktop screens, due to the 'container-10' wrapping class
  // this is needed due to the width calculation of the image, so it is always 2.5rem from left of screen
  @include media('>=1558px') {
    --QuoteSlider__gutter: calc((100vw - var(--container-max-width)) / 2);
  }

  @include media('<tablet-landscape') {
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      opacity: 0;
      width: 100%;
      top: -1rem;
      left: 0;
      z-index: -1;
      background-color: var(--QuoteSlider__theme);
      height: calc(100% + 2rem);
    }

    &--active {
      &::after {
        opacity: 1;
      }
    }
  }

  @include media('>=tablet-landscape') {
    position: relative;
    grid-area: 1/1;
    justify-self: flex-end;
    width: calc(100% + var(--QuoteSlider__gutter));
  }
}

// purely for the overflow hidden on the image and not the background element
.media-inner {
  position: relative;
  width: 100%;

  &::after {
    // adding transition to opacity on background color element, for when there are multiple cards, and some have images, some don't. Non-image cards do not want background color
    @include transition(opacity);
  }

  @include media('>=tablet-landscape') {
    &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: calc(100% + (var(--QuoteSlider--offset) * 2));
      right: var(--QuoteSlider--offset);
      z-index: -1;
      top: calc(-1 * var(--QuoteSlider--offset));
      background-color: var(--QuoteSlider__theme);
    }
  }

  // for the case where there is some that do have images, and some that don't so it's not always displaying
  &--notActive {
    &::after {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}

// purely for the overflow hidden on the image and not the background element
.media-second-inner {
  aspect-ratio: var(--QuoteSlider__aspect-ratio);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.quote-container {
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-compact);

  // needs to be explicitly set like this otherwise height of cards will change on slide change,
  // see storybook story for 'one-image'

  &--noImg {
    @include media('>=tablet-landscape') {
      width: 60%;
    }
  }

  &--img {
    @include media('>=tablet-landscape') {
      width: 60%;
    }
  }
}

.quote--outer {
  display: grid;
  grid-auto-rows: fit-content;
  position: relative;

  @include media('>=tablet-landscape') {
    grid-auto-rows: 1fr;
  }
}

.quote-inner {
  grid-area: 1 / 1;
  visibility: hidden;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  height: 0;

  &--active {
    height: auto;
    visibility: visible;
  }
}

.attribution {
  display: flex;
  gap: 0.5rem;

  &--inner {
    display: flex;
    flex-direction: column;
  }
}

.quote {
  color: var(--QuoteSlider__theme);

  @include fonts__quote;
}

.name {
  color: var(--QuoteSlider__theme);
}

.dash {
  @include media('>=tablet-landscape') {
    // to center it to name when on desktop
    padding-block-start: 0.35rem;
  }
}

.clientCompany {
  color: var(--colors__grey);
}

.attr {
  @include fonts__body--Soleil;

  @include media('>=tablet-landscape') {
    @include fonts__body-L--Soleil;
  }
}

.cta {
  margin-block-start: 1rem;

  &:focus-visible {
    outline-color: var(--colors__black);
  }
}
