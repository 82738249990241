@import './../../../styles/sass-resources/sass-resources';

.main {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  @include media('>=tablet-portrait') {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
  height: 100%;

  @include media('>=tablet-portrait') {
    padding: 2rem 2.5rem;
  }

  color: var(--colors__white);

  &.white {
    background-color: var(--colors__white);
    color: var(--colors__black);

    a {
      outline-color: var(--colors__black);
    }
  }

  &.purple {
    background-color: var(--colors__plum);
  }

  &.green {
    background-color: var(--colors__verde);
  }

  &.blue {
    background-color: var(--colors__azure);
  }

  &.gradient {
    background: var(--NPTC-Gradient);
  }
}

.header {
  @include fonts__h4;
}

.description {
  @include fonts__body--Soleil;
}
