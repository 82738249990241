@import './../../../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
  grid-column: span 1;

  &--textarea {
    @include media('>=tablet-landscape') {
      grid-column: span 2;
    }
  }

  width: 100%;
}

.label {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &-text {
    @include fonts__caption;

    color: var(--colors__black);

    &--error {
      color: var(--colors__error);
    }
  }
}

.textarea {
  min-height: 112px;
}

.input,
.textarea {
  padding: 1rem;
  width: 100%;
  background-color: var(--colors__frost);
  color: var(--colors__grey);
  border: solid 1px var(--colors__grey);
  border-radius: 4px;

  &:focus-within {
    outline-offset: 0.15rem;
    outline: 2px dotted currentcolor;
  }

  @include fonts__body--Soleil;
}
