@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.form {
  // figma says 5rem bottom padding
  padding-block-end: var(--spacing-L);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  box-sizing: border-box; /* Prevent padding from affecting width */

  @include media('>=tablet-landscape') {
    grid-template-columns: repeat(2, 1fr);
  }

  gap: 0.5rem 1rem;
}

.header {
  @include fonts__h7;
}

.submit-container {
  margin-block-start: 2rem;
  grid-column: span 1;
  display: flex;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    grid-column: span 2;
  }

  gap: 1.5rem;
}

.recaptcha {
  display: flex;

  @include media('<tablet-landscape') {
    flex-direction: column;
  }

  gap: 1.5rem;
}

.form-submission {
  @include fonts__body--Soleil;

  color: var(--colors__success);

  &--error {
    color: var(--colors__error);
  }
}

.recaptcha-input {
  position: relative;
}

.recaptcha-hidden-input {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0;
}

.cta {
  height: min-content;

  &:focus-visible {
    outline-color: var(--colors__black);
  }
}
