@import './../../../styles/sass-resources/sass-resources';

.main {
  --headline-color: var(--colors__white);
  --subheadline-color: var(--colors__dove);
  --desc-color: var(--colors__white);

  // need to add in images for each background color

  &.dark_mode {
    background-color: var(--colors__azure);
  }

  &.light_mode {
    --headline-color: var(--colors__black);
    --subheadline-color: var(--colors__grey);
    --desc-color: var(--colors__black);

    background-color: var(--colors__frost);
  }
}

.content-container {
  display: flex;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    max-width: 1086px;
  }
}

.headline {
  @include fonts__h3;

  &--large {
    @include fonts__h2;
  }

  color: var(--headline-color);
}

.subheadline {
  @include fonts__body-L--Soleil;

  color: var(--subheadline-color);
}

.desc {
  margin-block-start: 1.5rem;

  @include fonts__body--Soleil;

  color: var(--desc-color);
}

.cta {
  margin-block-start: 1.5rem;

  .main.light_mode & {
    outline-color: var(--colors__black);
  }
}
