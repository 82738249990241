@import './../../../../../styles/sass-resources/sass-resources';

.main {
  height: var(--Burger__height);
  width: var(--Burger__width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.burger-bar {
  width: 1.5rem;
  height: 0.15rem;
  background-color: var(--colors__white);
  border-radius: 0.5em;

  @include transition(transform 0.4s);
}

.clicked {
  &:nth-child(1) {
    transform: rotate(45deg) translate(0.3em, 0.45em);
  }

  &:nth-child(2) {
    transform: scale(0.1);
  }

  &:nth-child(3) {
    transform: rotate(135deg) translate(-0.3em, 0.45em);
  }
}

.unclicked {
  transform: rotate(0) translate(0);
}
