@import './../../../../../styles/sass-resources/sass-resources';

.main {
  @include media('>=tablet-landscape') {
    display: none;
  }

  display: flex;
}

.content {
  --transition-duration: 0.7s;

  &[data-state='closed'] {
    @include doNothing;
  }
}

.wrapper {
  background: var(--colors__white);
  position: fixed;
  width: 100%;
  top: var(--NavigationHeader__height);
  height: calc(100dvh - var(--NavigationHeader__height));
  z-index: 3;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  --transition-duration: 0.7s;

  [data-state='open'] & {
    @include slideInDown;
  }

  [data-state='closed'] & {
    @include slideOutDown;
  }
}

.hidden-close {
  position: absolute;
  top: 0;
  right: calc(var(--container__padding-x));
  width: 30px;
  height: var(--NavigationHeader__height);
  display: flex;
  align-items: center;
  z-index: 10;
}

.hidden-close-trigger {
  display: block;
  height: var(--Burger__height);
  width: var(--Burger__width);
}

.mobile-menu-toggle,
.hidden-close-trigger {
  &:focus-visible {
    outline: dotted 2px var(--colors__white);
    outline-offset: 5px;
  }
}
