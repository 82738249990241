@import './../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
  display: flex;
  overflow: hidden;

  // on mobile, padding top is 160px as per figma spec
  @include media('<tablet-landscape') {
    --spacer__padding-top: calc(10rem - 100px);

    flex-direction: column;
  }

  @include media('>=tablet-landscape') {
    // on desktop, padding bottom is 80px (default for spacer-L) + 30px
    --spacer__padding-bottom: 110px;
  }

  &.blue {
    background-color: var(--colors__azure);
  }

  &.green {
    background-color: var(--colors__verde);
  }

  &.purple {
    background-color: var(--colors__plum);
  }

  &.gradient {
    background: var(--NPTC-Gradient);
  }
}

.img-desktop {
  // need to use !important to override nextjs fill styles

  overflow: visible;
  right: 0;
  object-position: center bottom;
  aspect-ratio: 924 / 712;
  height: 240px !important;
  padding-block-end: 40px;

  @include media('>=tablet-landscape') {
    height: 100% !important;
    top: 0;
    left: auto !important;
    object-position: left center;
    width: 50% !important;
    padding-block-end: 0;
  }
}

.content-container {
  padding-block-start: 100px;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    padding-inline-end: 0;
    padding-block-start: 0;
    max-width: 55%;
  }
}

.headline {
  @include fonts__h2;

  color: var(--colors__white);
}

.desc {
  @include fonts__body-L--Soleil;

  color: var(--colors__white);
}
