@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  overflow-x: hidden;
}

.tabs-wrapper {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(90deg, rgb(246 246 246 / 0%) 0%, #f6f6f6 90%);
    pointer-events: none;

    @include transition(opacity 0.5s);
  }
}

.tabs-container {
  position: relative;
  display: flex;
  width: 100%;
  gap: var(--spacing);
  border-bottom: solid 1px var(--colors__grey);
  overflow-x: auto;
  padding-inline-end: 2rem; // so that we can see the last element fully
  white-space: nowrap; // Prevents wrapping of tabs
  -webkit-overflow-scrolling: touch;

  // customizing scrollbar
  scrollbar-width: thin;
}

.cards-container {
  padding-block: 2rem;
}

.tab {
  padding-block: 0.5rem;
  cursor: pointer;
  flex-shrink: 0;

  @include focus-inset;
}

.indicator {
  background-color: var(--colors__azure);
  height: 4px;
  width: var(--width);
  bottom: 0;
}

.tab-text {
  @include fonts__primary-cta;

  color: var(--colors__grey);
}
