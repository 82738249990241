@import './../../../styles/sass-resources/sass-resources';

.wrapper {
  &.light_mode {
    background-color: var(--colors__frost);
  }

  &.dark_mode {
    background-color: var(--colors__azure);
  }
}

.main {
  --Richtext__spacing-y-override: 1rem;

  display: flex;
  align-items: center;
  gap: var(--spacing);
  flex-direction: column;

  @include media('>=tablet-landscape') {
    gap: 2.5rem;
    flex-direction: row;
    align-items: flex-start;
  }

  &.image_on_right {
    @include media('>=tablet-landscape') {
      flex-direction: row-reverse;
    }
  }
}

.image-container {
  overflow: hidden;
  width: 100%;

  @include media('>=tablet-landscape') {
    width: 50%;
  }

  aspect-ratio: 1.5 / 1;
  position: relative;
}

.CTA {
  @include media('>=tablet-landscape') {
    width: 50%;
    align-self: center;
  }
}
