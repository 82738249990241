@import './../../../styles/sass-resources/sass-resources';

.main {
  &.black {
    @include transition(background-color, color);

    border: solid 2px var(--colors__black);

    &:hover {
      background-color: var(--colors__black);
      color: var(--colors__white);
    }
  }

  &.white {
    @include transition(border-color);

    color: var(--colors__white);
    border: solid 2px var(--colors__grey);

    &:hover {
      border-color: var(--colors__white);
    }
  }

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
}

.svg {
  width: 2rem;
  height: 2rem;
}
