@import './../../../styles/sass-resources/sass-resources';

.wrapper {
  display: flex;
  flex-direction: column;

  &--indented {
    align-items: flex-end;
  }
}

.main {
  // max width to allow for indentation on left and right
  max-width: 1086px;
}

.main-heading {
  @include fonts__h4;
}

.sub-heading {
  @include fonts__body-L--Soleil;

  color: var(--colors__grey);
}

.heading__wrapper {
  padding-block-end: 1.5rem;
}
