@import './../../../styles/sass-resources/sass-resources';

.main {
  --Card-scale: 1;
  --Card-play-container-bg: var(--colors__black);
  --Richtext__spacing-y-override: 1rem;

  height: 100%;
  align-content: stretch;
  display: flex;
  flex-direction: column;
  background-color: var(--colors__white);
  min-height: 200px;

  @include transition(box-shadow);

  &:hover {
    --Card-scale: 1.1;
    --Card-play-container-bg: var(--colors__grey);

    box-shadow: 0 4px 40px 0 rgb(0 0 0 / 25%);
  }

  @include media('>=tablet-portrait') {
    min-height: 480px;
  }
}

.play-container {
  border: 5px solid var(--colors__white);
  border-radius: 50%;

  // from the Figma -> 20px padding * 2 + 16px icon width + 5px border width * 2
  width: 66px;
  aspect-ratio: 1;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  right: 10%;

  @include transition(background-color);

  background-color: var(--Card-play-container-bg);
}

// this inner container is needed for overflow hidden to be applied to img only, and not the play button container
.img--inner {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 17px;
  width: 17px;
  color: var(--colors__white);
  transform: translate(-50%, -50%);
}

.img-container {
  position: relative;
  aspect-ratio: 328 / 208;
  margin: 1.5rem;
  margin-block-end: 0;
  width: calc(100% - 3rem);

  img {
    @include transition(transform 0.5s);

    transform: scale(var(--Card-scale));
  }

  &--large {
    aspect-ratio: 328 / 288;
  }
}

.content-container {
  // flex grow so that the date (which is aligned at end of container) can stretch to end of container
  flex-grow: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-compact);
  height: max-content;
}

.heading-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.eyebrow {
  @include fonts__subhead;

  color: var(--colors__grey);
}

.series-title {
  color: var(--colors__grey);

  @include fonts__all-caps-S;
}

.tag-title {
  @include fonts__body-S--bold;

  background-color: var(--colors__light-azure);
  border-radius: 600px;
  padding: 0.25rem 0.75rem;
  width: fit-content;
}

.title {
  @include fonts__body-L--Tiempos;
}

.desc {
  @include fonts__paragraph;
}

.date {
  @include fonts__eyebrow;

  color: var(--colors__grey);
  margin-top: auto;
}
