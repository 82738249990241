@import './../../../styles/sass-resources/sass-resources';

.main {
  text-decoration: underline;

  &.footer-p {
    @include fonts__paragraph--small;

    line-height: 1.5;
    text-underline-offset: -2px;
  }

  &.body-p {
    @include fonts__footer-p;

    line-height: 1.5;
    text-underline-offset: -2px;
  }

  &.body-soleil {
    @include fonts__body--Soleil;
  }
}
